.onboard-dajumeals-main{

  color: #fff;
  border-radius: 10px;
  background-color: #FBA808;
  /* background-image: url(../public/back.png); */
  background-size: fill;
  display: flex;
  flex-direction: column;
  padding: 15px 80px;
}

.onboard-dajumeals-main-logo{
  display: flex;
  font-size: 20px;
  justify-content:space-between;
  /* flex-wrap: wrap; */

  
}
.onboard-dajumeals-main-heading{
  font-size: 70px;
  font-weight: 900;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0px;

  
}

.onboard-dajumeals-main-des{
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}


.sp{
  margin:10px;
}
.onboard-dajumeals-mains{
  height: 60vh;

  color: #fff;
  opacity: 2;
  /* border-radius: 10px; */
  /* background-color: #FBA808; */
  /* background-image: url(https://media.istockphoto.com/photos/pizza-delivery-guy-on-bicycle-picture-id1194649943?k=20&m=1194649943&s=612x612&w=0&h=pQG2y5Me_0nYhyN_U44jDqyrGI05HsUqx_cQ1HTVsgY=); */
 /* background-image: url(https://t4.ftcdn.net/jpg/02/96/70/03/360_F_296700369_9FQyrbRyZceXzLU7GfyMwYoMYCSOChEC.jpg); */
 background-image: url('../public/back.png'); 
 /* background-image: url('../public/he.png'); */
 background-size: cover;
  display: flex;
  flex-direction: column;
  position: center;

  padding: 15px 80px;
}
.onboard-dajumeals-main-headings{
  font-size: 70px;
  font-weight: 900;
  
  margin: 0;
  padding: 0;
  margin-top: 120px;
  margin-bottom: 0px;
  /* filter: blur(18px); */


}



@media screen and (max-width: 600px) {
  .logo{
    height: 70px;
    width: 70px;
    margin-top: 40px;
  }
  .hd{
    visibility: hidden;
  }
  .onboard-dajumeals-main-heading{
    font-size: 40px;
    font-weight: 900;

    margin-top: 10px;
    margin-bottom: 0px;
  
    
  }
  .onboard-dajumeals-mains{
    height: 80vh;
    background-size: cover;
    padding:2px 8px;
    /* background-image: url(https://static.toiimg.com/photo/msid-80481988/80481988.jpg?996514); */
    background-image: url('../public/back.png')
  }

  .onboard-dajumeals-main-headings{
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
  
    
  }
  .onboard-dajumeals-main-des{
    font-size: 18px;

    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  .onboard-dajumeals-main-logo{
    display: flex;
    font-size: 20px;
    justify-content:space-between;
    flex-wrap: wrap;
    flex-direction: column;
  
    
  }
  .onboard-dajumeals-main-des{
    font-size: 18px;
text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
  

}

