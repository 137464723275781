.cta{
    /* background-color: #411a1a; */
  /* margin: 60px !important; */
  /* padding: 10vh 11.402vh;  */
  padding: 20px 22px;
  /* height: 30vh; */

}

.boxe{
    /* background-color: #f5f5f5; */
    border: 3px solid #E6E6E6;
    border-radius: 5px;
    /* padding: 4vh 2.85vh; */
    box-sizing: border-box;
    /* width: 1300p; */
    height: 15vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
   flex-wrap: wrap;

}

.bt{
    background: #FC5530 !important;
    box-shadow: inset 0px -3px 0px #fc352b !important;
    border-radius: 4px !important;
    font-weight: bolder !important;
    font-size: 22px !important;
    line-height: 3.89768574908648vh !important;
    letter-spacing: 0.01em !important;
    text-transform: capitalize !important;
    color: #FFFFFF !important;
    height: 7vh !important;
    width: 18vw !important;
}

.bt2{
  background: #FC5530 !important;
  box-shadow: inset 0px -3px 0px #fc352b !important;
  border-radius: 4px !important;
  font-weight: bolder !important;
  font-size: 22px !important;
  line-height: 3.89768574908648vh !important;
  letter-spacing: 0.01em !important;
  text-transform: capitalize !important;
  color: #FFFFFF !important;
  height: 7vh !important;
  width: 60%;
}



.mobileButton{
  display: flex;
height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px !important;
}

