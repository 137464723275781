.stepo{
    /* height: 400px !important; */
    display: flex;
    flex-direction: column ;
    justify-content: start ;
    background-color: #fbaa0847;
    padding-top: 30px ;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px ;
  
    /* flex-wrap: wrap !important; */

}

h1{

    font-weight: bolder;
    font-size: 60px !important;
}
h2{

    font-weight: bolder;
    font-size: 40px !important;
}
.test{
    display: flex;
    flex-direction: row !important;
    justify-content: space-around;
    padding-top: 40px;
    flex-wrap: wrap;
    align-items: center;
    
}

.imgs{

    height: 80px !important;
    width: 80px !important;
    padding: 10px;
}

@media only screen and (max-width:600px) {
    .stepo{
        /* height: 400px !important; */
        display: flex;
        flex-direction: column ;
        justify-content: start ;
        background-color: #fbaa0847;
        padding-top: 30px ;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px ;
        text-align: center;
      
        /* flex-wrap: wrap !important; */
    
    }
    .test{
        display: flex;
        flex-direction: row !important;
        justify-content: center;
        padding-top: 40px;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
      }
    
}

