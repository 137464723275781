.why-center{
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 50px !important;
   
}


.about-border {
    display: block;
    width: 80px;
    height: 3px;
    background: #FBA808;
    margin: 20px auto;
}

.cont2-dajumeals{
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px ;
    padding-top: 40px ;
display: flex;
flex-direction: row ;
justify-content: space-around;
font-size: 18px;
flex-wrap: wrap ;
}

img{
    width: 180px;
    height: 180px;

}

.centerItems{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
   
    padding: 10px 20px;

    font-size: 40px !important;
}

/* .hos{
    height: 400px !important;
} */