$base-color: #e67e22;
$blue-color: #22ac11;
$brand-color:#FBA808;
$white:#fff;
$greys:#ededed;
.form-control{
  outline: none;
  height: 60px;
  // width:40px;
  box-shadow: none;
  color: unset;
  background-color:$greys ;
  // border-color: $brand-color;
}
.form-control:focus {
    outline: none;
    height: 60px;
    box-shadow: none;
    color: unset;
    background-color: $greys;
    border-color: $brand-color;
  }

  .btn {
    letter-spacing: 1px;
  }
  
  .steps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    letter-spacing: 1px;
    margin-top: 2rem;

  
    .step {
      margin: 0.5rem 3rem;
      display: flex;
      flex-direction: column;
      color: #fff;
  
      a {
        color: $base-color;
      }
  
      div:first-of-type {
        border: 1px solid #fff;
        padding: 5px;
        border-radius: 50%;
        margin-right: 0.5rem;
        width: 30px;
        height: 30px;
        // height: 2rem;
        text-align: center;
        line-height: 100%;
        // padding-top: 7%;
   
      }
  
      :last-child {
        margin-top: 3%;
      }
  
      &.active {
        div:first-of-type {
          background-color: $white;
          border: 1px solid transparent;
          color:$brand-color;
          vertical-align: center;
        }
  
        div:last-of-type {
          color:$white;
        }
      }
    }
  }
  
  .input-form {
    width: 60%;
    margin: 3rem auto;
  }
  
  .errorMsg {
    max-width: 350px;
    color: #f21e08;
    padding: 2px 0;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 300;
  }
  
  .login-error {
    font-size: 1.1rem;
  }
  
  .successMsg {
    color: $blue-color;
  }
  
  .loading {
    color: #777;
  }
  
  .form-control.input-error {
    border: 2px solid #f21e08;
  }
  
  .user-details {
    color: $blue-color;
    padding: 5px 0;
    margin-bottom: 1rem;
  }
  
  @media screen and (max-width: 1200px) {
    .input-form {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 900px) {
    .steps .step {
      margin: 0.5rem 2rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    h1 {
      font-size: 2rem;
    }
  
    .steps .step {
      margin: 0.5rem 1rem;
      font-size: 0.9rem;
  
      div:first-of-type {
        padding-top: 9%;
      }
    }
    .boxe{
      display: none;
    }
    .cta{
      display:none;
    }
    .footer {
      background-color: white;

      position: fixed;
      width: 100%;
      bottom: 0;
      color: black;
      font-size: 20px;
      z-index: 20;
      display: flex;
      align-content: center;
      align-items: end;
      justify-content: space-around;
     }
     .bt{

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 18vw !important;
    // width: 40vw;

     }
   
  }
  
  @media screen and (max-width: 400px) {
    .steps {

      flex-direction: row;
      align-items: center;
  
      .step {
        align-items: center;
      }
    }
   
    .boxe{
      display: none;
    }
    .cta{
      display:none;
    }
    .footer {
      background-color: white;

      position: fixed;
      width: 100%;
      bottom: 0;
      color: black;
      font-size: 18px;
      z-index: 20;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
     }
     .bt{

      display: inline-block;
      justify-content: center;
      align-items: center;
      // width: 20px;
      margin: 10px;
      font-size: 12px;
  
       }

  }

  a{
    color: white;
  }
  
  a:hover{
    color: white;
  }


 body{
    font-family:'Blinker', sans-serif;
  }


footer{
  display: none;
}